<template>
  <Field
    v-bind="$attrs"
    as="select"
    name="field"
    class="select"
    :class="`select--${theme}`"
    :value="modelValue"
    @change="onChange($event)"
  >
    <option value disabled>{{ placeholder }}</option>
    <option
      v-for="(option, index) in options"
      :key="`option-${option.value}-${index}`"
      :value="option.value"
      :selected="modelValue == option.value"
      :disabled="option.disabled"
    >
      {{ option.label }}
    </option>
  </Field>
  <ErrorMessage name="field" class="field__error" />
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    theme: {
      type: String,
      default: "default",
    },
  },

  emits: ["input"],

  methods: {
    onChange(e) {
      const selected = this.options.find(
        (option) => e.target.value == option.value
      );
      return this.$emit("input", selected.value);
    },
  },
};
</script>

<style>
.select {
  outline: none !important;
  @apply w-full h-10 text-sm dark:bg-gray-850 dark:text-gray-300 dark:border dark:border-gray-700;
}

.select--default {
  @apply bg-gray-100 border-0 rounded px-3;

  &:hover,
  &:focus {
    @apply ring-2 ring-primary-200 ring-inset;
  }
}
</style>
